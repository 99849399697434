<template>
  <div class="reservation">
    <div class="header">
      <Heading
        tag="h1"
        customClass="page-title"
        class="reserved-area-titles title"
        :label="$t('pages.personalPage.reservations.pageTitle')"
      ></Heading>
    </div>
    <div class="content">
      <div class="header--container">
        <div
          class="car-img"
          :style="{
            backgroundImage: `url('${img}')`,
          }"
        />
        <PriceModelDetails
          class="mb-6"
          icon-name="info-circle"
          tooltipPosition="left"
          :vehicle="getReservationVehicle"
          :absolutePrice="!getReservationWithFinance"
          :product-title="getReservationVehicle.model"
          :product-description="getReservationVehicle.commercialDescription"
          :tooltip-description="getReservationVehicle.tooltip"
          :price-label="getVehicleTotalPriceOrPvpFormatted"
          :modality="formattedMonthlyPrice"
          :finance-taeg="formattedTaeg"
          :showActionsMissingTag="missingActionsRetake"
        />
      </div>
      <div v-if="missingActionsRetake">
        <div class="separator" />
        <div class="action-cards">
          <div class="actions-title">
            <Label customClass="section-title" color="primary" bold="semibold">
              {{ $t("missingActions.sectionTitle") }}
            </Label>
          </div>
          <div class="action-cards--wrapper">
            <CardWithAction
              v-for="(cardContent, index) in cardsWithAction"
              :key="index"
              @onRedirect="cardContent.onRedirect"
              :title="cardContent.title"
              :description="cardContent.description"
              :type="cardContent.type"
              :warningLabel="cardContent.warningLabel"
              backgroundColor="var(--color-6)"
            />
          </div>
        </div>
      </div>
      <div class="separator" />
      <div class="labelBox vertical">
        <div class="general">
          <Label customClass="section-title" color="primary" bold="semibold">
            {{
              $t(
                "pages.personalPage.reservations.reservationDetails.generalInformation"
              )
            }}
          </Label>
          <DisplayLabelValue
            spaceBetween
            :data="getReservationVehicleGeneralWithLabel"
          />
        </div>
      </div>
      <div class="separator" v-if="getReservationRetake" />
      <Label
        customClass="section-title"
        color="primary"
        bold="semibold"
        v-if="getReservationRetake"
      >
        {{
          $t(
            "pages.personalPage.reservations.reservationDetails.retakeInformation"
          )
        }}
      </Label>
      <div class="labelBox vertical" v-if="getReservationRetake">
        <div class="general">
          <DisplayLabelValue
            spaceBetween
            :data="getReservationRetakeLeftWithLabels"
          />
          <DisplayLabelValue
            spaceBetween
            :data="getReservationRetakeRightWithLabels"
          />
        </div>
        <div class="general">
          <div class="retake-value-section">
            <Label customClass="retake-value-section--title">
              {{
                $t(
                  "pages.personalPage.reservations.reservationDetails.retakeValue"
                )
              }}:
            </Label>
            <Label
              customClass="retake-value-section--value"
              :size="isRetakeGreaterThanReserve ? 'small' : 'medium'"
              :bold="isRetakeGreaterThanReserve ? 'normal' : 'semibold'"
            >
              {{ getReservationRetakeValue }}
            </Label>
          </div>
          <div class="retake-value-section" v-if="isRetakeGreaterThanReserve">
            <Label customClass="retake-value-section--title">
              {{
                $t(
                  "pages.personalPage.reservations.reservationDetails.returnValue"
                )
              }}:
            </Label>
            <Label customClass="retake-value-section--value">
              {{ getReservationRetakeDiff }}
            </Label>
          </div>
          <Alert
            v-if="isRetakeGreaterThanReserve"
            class="info-retake-value"
            type="info-alert"
            :message="$t('pages.detailsPage.retakeAlert')"
          />
        </div>
      </div>
      <div class="separator" v-if="getReservationWithFinance" />
      <div class="labelBox vertical">
        <div class="general" v-if="getReservationWithFinance">
          <Label customClass="section-title" color="primary" bold="semibold">
            {{
              $t(
                "pages.personalPage.reservations.reservationDetails.simulationData"
              )
            }}
          </Label>
          <DisplayLabelValue
            spaceBetween
            :data="getReservationFinanceWithLabels"
          />
        </div>
      </div>
      <div class="separator" />
      <div class="labelBox vertical">
        <div class="general">
          <Label customClass="section-title" color="primary" bold="semibold">
            {{
              $t("pages.personalPage.reservations.reservationDetails.delivery")
            }}
          </Label>
          <Radio
            class="delivery-type-radio my-6"
            forId="deliveryType"
            :checked="true"
            :label="
              getDeliveryType === 'HOME'
                ? $t(
                    'pages.personalPage.reservations.reservationDetails.deliveryTypes.HOME',
                    {
                      homeDeliveryCost: homeDeliveryCost,
                    }
                  )
                : $t(
                    'pages.personalPage.reservations.reservationDetails.deliveryTypes'
                  )[getDeliveryType]
            "
          />
          <Label
            color="tertiary"
            bold="semibold"
            size="small"
            v-if="getDeliveryType !== 'DEALER'"
          >
            {{
              $t(
                "pages.personalPage.reservations.reservationDetails.deliverySubtitle"
              )
            }}
          </Label>
          <DisplayLabelValue
            spaceBetween
            :data="getReservationDelivery"
            v-if="getDeliveryType !== 'DEALER'"
          />
          <DisplayLabelValue
            spaceBetween
            :data="getReservationDeliveryDealer"
            v-else
          />
        </div>
      </div>
      <div class="separator" />
      <div class="labelBox vertical">
        <div class="personal">
          <Label customClass="section-title" color="primary" bold="semibold">
            {{
              $t(
                "pages.personalPage.reservations.reservationDetails.personalInformation"
              )
            }}
          </Label>
          <DisplayLabelValue spaceBetween :data="getReservationUserWithLabel" />
        </div>
        <div class="receipt">
          <Label customClass="section-title" color="primary" bold="semibold">
            {{
              $t(
                "pages.personalPage.reservations.reservationDetails.billingData"
              )
            }}
          </Label>
          <DisplayLabelValue
            spaceBetween
            :data="getReservationReceiptWithLabels"
          />
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="close-btn">
        <Button
          @onClick="goToReservations"
          fill
          type="primary"
          label="Fechar"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Label, Button } from "../../../atoms";
import {
  DisplayLabelValue,
  PriceModelDetails,
  CardWithAction,
} from "../../../molecules";
import { Radio, Alert, Heading } from "@sc/ds-ui-component-library";
import {
  formatNonCurrency,
  getPriceFormatted,
} from "../../../../utils/currency";
import { PAGES } from "../../../../enums/pages";

export default {
  name: "ReservationDetails",
  components: {
    Label,
    DisplayLabelValue,
    PriceModelDetails,
    Button,
    CardWithAction,
    Radio,
    Alert,
    Heading,
  },
  data() {
    return {
      deliveryTypes: {
        HOME: 0,
        DEALER: 1,
        CUSTOM: 2,
      },
    };
  },
  methods: {
    goToReservations() {
      this.$router.push({
        name: "reservations",
      });
    },
    redirectQ2Form() {
      this.$router.push({
        name: PAGES.PERSONAL_RETAKE,
      });
    },
  },
  computed: {
    ...mapGetters([
      "getDropdownFinance",
      "getReservationVehicle",
      "getReservationUserWithLabel",
      "getReservationReceiptWithLabels",
      "getReservationDelivery",
      "getReservationDeliveryDealer",
      "getReservationFinanceWithLabels",
      "getReservationRetakeValue",
      "getReservationRetakeDiff",
      "isRetakeGreaterThanReserve",
      "getReservationVehicleGeneralWithLabel",
      "getReservationRetakeLeftWithLabels",
      "getReservationRetakeRightWithLabels",
      "getReservationRetake",
      "getReservationCurrentRetakeSubmitted",
      "getReservationWithFinance",
      "getVehicleTotalPriceOrPvpFormatted",
      "getDeliveryType",
    ]),
    homeDeliveryCost() {
      return this.getDropdownFinance?.homeDeliveryCost || "---€";
    },
    img() {
      return (
        this.getReservationVehicle?.imageUrl ||
        require("../../../../assets/images/placeholder.jpg")
      );
    },
    formattedTaeg() {
      return (
        "TAEG: " +
        formatNonCurrency(this.getReservationVehicle?.taeg, 1, 1, true) +
        "%"
      );
    },
    formattedMonthlyPrice() {
      return getPriceFormatted(
        Number(this.getReservationVehicle?.monthlyPrice)
      );
    },
    missingActionsRetake() {
      return (
        this.getReservationRetake && !this.getReservationCurrentRetakeSubmitted
      );
    },
    cardsWithAction() {
      const cardActionContents = [
        {
          onRedirect: this.redirectQ2Form,
          title: this.$t("missingActions.retake.title"),
          description: this.$t("missingActions.retake.description"),
          warningLabel: this.$t("missingActions.retake.warningLabel"),
          type: this.$t("missingActions.retake.type"),
        },
      ];

      if (!this.missingActionsRetake) cardActionContents.shift();

      return cardActionContents;
    },
  },
  async mounted() {
    await this.$store.dispatch("StoreModule/fetchDealers");
    await this.$store.dispatch("storeReservations");
    await this.$store.dispatch("storeDropdowns");
    this.$store.commit("setCurrentReservation", Number(this.$route.params.id));
  },
};
</script>

<style lang="scss" scoped>
.header {
  border-bottom: 2px solid #e4ecf0;
  padding-bottom: 1.25rem;
}

.section-title.label--small {
  line-height: var(--text-1-line-height);
  font-size: var(--subtitle-2-font-size);
}

.retake-value-section {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  &--title {
    color: var(--color-2);
  }

  &--value {
    text-align: right;
    color: var(--theme-color-secondary);
  }
}

.retake-value-section--value.label--medium {
  line-height: var(--title-3-line-height);
  font-size: var(--title-3-font-size);
  color: var(--theme-color-secondary);
}

.retake-value-section--value {
  color: var(--color-1);
}

.content {
  margin-top: 1.25rem;
}

.page-title {
  font-weight: 800;
  font-size: 24px;
}

.separator {
  padding-top: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid #e4ecf0;
}

.footer {
  margin-top: 2.5rem;
  display: flex;
  justify-content: flex-end;
}

.footer .button {
  width: 182px;
  height: 50px;
}

.close-btn {
  margin-left: 1.5rem;
}

.labelBox {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;

  ::v-deep .label--tertiary {
    color: var(--color-1);
  }

  .delivery-type-radio {
    ::v-deep .icon {
      fill: var(--theme-color-tertiary);
    }
  }
}

.retake {
  grid-row-gap: 0;
}

.header--container {
  display: flex;
  align-items: center;
}

.car-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 155px;
  margin-right: 1rem;
}

.action-cards {
  display: flex;
  flex-direction: column;
}

.action-cards--wrapper {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
}

::v-deep .text-secondary {
  color: var(--theme-color-secondary);
}
.info-retake-value {
  margin-top: 22px;
}

@media (max-width: 1680px) {
  .reservation {
    .header {
      .label {
        line-height: 18px;
      }
      padding-bottom: 16px;
    }
  }

  .content {
    margin-top: 16px;
  }

  .separator {
    margin-bottom: 16px;
    padding-top: 16px;
  }

  .container {
    margin-top: 8px;
  }

  .my-6 {
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .section-title {
    &.label--small {
      --text-1-line-height: 16px;
      --subtitle-2-font-size: 16px;
    }
  }

  ::v-deep .block {
    margin-bottom: 0;

    .label {
      &.gray-label {
        font-size: 14px;
        line-height: 18px;
      }
      &.text-left {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  ::v-deep .choice--container {
    .choice--label {
      --choice-label-line-height: 18px;
      --choice-label-font-size: 14px;
    }

    .text-secondary {
      line-height: 18px;
      font-size: 14px;
    }
  }

  ::v-deep .labelBox {
    .label--tertiary {
      line-height: 14px;
      font-size: 14px;
    }
  }

  ::v-deep .card--content {
    .card-content {
      padding: 8px;

      .card-content--title {
        .label {
          --subtitle-2-font-size: 16px;
          --text-1-line-height: 16px;
        }
      }

      .card-content--description {
        .label {
          --text-2-font-size: 14px;
          --text-2-line-height: 18px;
        }
      }
    }
  }

  ::v-deep .header--container {
    .container--wrapper {
      margin-bottom: 0;
    }
  }

  ::v-deep .footer {
    margin-top: 52px;
  }

  ::v-deep .retake-value-section--title {
    font-size: 14px;
    line-height: 14px;
  }

  ::v-deep .retake-value-section--value {
    --title-3-font-size: 22px;
    --title-3-line-height: 22px;
  }
}
@media (max-width: 1366px) {
  .separator {
    margin-bottom: 8px;
    padding-top: 8px;
  }

  .section-title {
    &.label--small {
      --text-1-line-height: 14px;
      --subtitle-2-font-size: 14px;
    }
  }

  ::v-deep .block {
    margin-bottom: 0;

    .label {
      &.gray-label {
        font-size: 12px;
        line-height: 16px;
      }
      &.text-left {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  ::v-deep .choice--container {
    .choice--label {
      --choice-label-line-height: 16px;
      --choice-label-font-size: 12px;
    }

    .text-secondary {
      line-height: 16px;
      font-size: 12px;
    }
  }

  ::v-deep .labelBox {
    .label--tertiary {
      line-height: 12px;
      font-size: 12px;
    }
  }

  ::v-deep .card--content {
    .card-content {
      .card-content--title {
        .label {
          --subtitle-2-font-size: 14px;
          --text-1-line-height: 14px;
        }
      }

      .card-content--description {
        .label {
          --text-2-font-size: 12px;
          --text-2-line-height: 16px;
        }
      }
    }
  }

  ::v-deep .footer {
    margin-top: 40px;
  }

  ::v-deep .retake-value-section--title {
    font-size: 12px;
    line-height: 12px;
  }

  ::v-deep .retake-value-section--value {
    --title-3-font-size: 20px;
    --title-3-line-height: 20px;
  }
}
@media (max-width: 1280px) {
  ::v-deep .footer {
    margin-top: 28px;
  }
}
@media (max-width: 1024px) {
  .labelBox {
    word-break: break-word;
  }

  .footer {
    display: flex;
    flex-direction: column;
    & > * {
      margin: 5px 0 !important;
    }

    & > *:first-child {
      margin: 0 0 5px 0 !important;
    }

    & > *:last-child {
      margin: 5px 0 0 0 !important;
    }

    .close-btn {
      width: 100%;
      margin: 0;

      .button {
        width: 100%;
        justify-content: center;
      }
    }
  }
}
@media (max-width: 768px) {
  .labelBox {
    grid-template-columns: 1fr;
  }

  .labelBox-retake {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .action-cards--wrapper {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}
@media (max-width: 375px) {
}

.reserved-area-titles {
  --heading-xlarge-font-size: 24px;
  --heading-xlarge-line-height: 31px;
  --heading-color: var(--theme-color-secondary);
  --heading-xlarge-weight: 700;
}
</style>
